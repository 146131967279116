<template>
  <div>
    <div class="developer-center">
      <img src="/static/img/img_developer_center.png">
      <div class="developer-center-content main">
        <div class="h8">파트너 개발자 센터</div>
        <div class="h3">기존 소스코드를 활용하여<br>
          <span class="valuable">새로운 가치를 만들어 판매</span>하세요.</div>
        <div class="body2 sub2">
          파트너 개발자는 기존 소스코드를 내려받아 일부 수정한 후 또다른 템플릿을 만들어 판매할 수 있습니다. 파트너 개발자가 되어 수익을 올리고 비즈니스를 확장하세요.
        </div>
        <div class="status-container">
          <div class="status" :class="`status-${approvalStatus}`" v-if="approvalStatus !== 0">
            <img :src="`/static/icon/${status[approvalStatus].icon}.svg`">
            <div class="status-text">{{  status[approvalStatus].text  }}</div>
          </div>
        </div>
        <div class="buttons">
          <a href="https://developers.launchpack.co.kr/developers/" target="_blank">
            <button class="button is-dark">파트너 개발자 센터</button>
          </a>
          <button class="button is-main" @click="clickDeveloperRequest">파트너 개발자 신청</button>
        </div>
      </div>
      <sweet-modal overlay-theme="dark" ref="profileModal" hideCloseButton
      :enableMobileFullscreen="false">
        <div class="modal-confirm-content">
          <img src="/static/icon/u_exclamation-circle.svg" class="svg-sub4" style="width: 40px;height: 40px;">
          <div>
            <div class="subtitle6 main">파트너 개발자 프로필 작성 완료 후 신청해 주세요</div>
            <div class="body4 sub2">정보 입력 및 심사 후 판매를 할 수 있습니다.</div>
          </div>
          <div class="flex" style="gap: 8px;margin-top: 12px;width:100%">
            <!-- test -->
            <!-- <button class="button" @click="$refs.profileModal.close()">다음에 작성하기</button> -->
            <button class="button" @click="$router.push('/partner_center/developer_request')">다음에 작성하기</button>
            <button class="button is-primary" @click="clickProfile">프로필 작성하기</button>
          </div>
        </div>
      </sweet-modal>
      <sweet-modal overlay-theme="dark" ref="approvalModal" hideCloseButton
      :enableMobileFullscreen="false">
        <div class="modal-confirm-content">
          <img src="/static/icon/u_exclamation-circle.svg" class="svg-sub4" style="width: 40px;height: 40px;">
          <div>
            <div class="subtitle6 main">파트너 개발자 심사중입니다.</div>
            <div class="body4 sub2">심사가 완료되면 알림톡으로 알려드려요</div>
          </div>
          <div class="flex" style="gap: 8px;margin-top: 12px;width:100%">
            <button class="button" @click="$refs.approvalModal.close()">확인</button>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin'
export default {
  name: "DeveloperCenter",
  mixins: [
    PartnerAPIMixin
  ],
  components: {
    SweetModal
  },
  data() {
    return {
      status: {
        1: {
          text: '파트너 개발자 심사중입니다. 완료되면 알림톡으로 알려드려요',
          icon: 'u_comment-exclamation'
        },
        2: {
          text: '파트너 개발자 신청이 승인되었습니다.',
          icon: 'u_check-info'
        },
        3: {
          text: '파트너 개발자 신청이 반려되었습니다.',
          icon: 'u_comment-exclamation'
        }
      }
    }
  },
  computed: {
    approvalStatus() {
      return this.user.partner && this.user.partner.request_status ? this.user.partner.request_status : 0;
    }
  },
  created() {
    this.getPartnerInfo();
  },
  methods: {
    getPartnerInfo() {
      if(this.user.partner) {
        this.request.partner.get('developer_request').then((res) => {
          this.user.partner.request_status = res.data.request_status;
          this.$store.commit('setUser', this.user);
        });
      }
    },
    clickDeveloperRequest() {
      if(this.user.partner.request_status === 1) {
        this.$refs.approvalModal.open()
      } else if(this.requiredProfile()) {
        this.$refs.profileModal.open()
      } else {
        this.$router.push('/partner_center/developer_request')
      }
    },
    requiredProfile() {
      let result = false;
      if(!this.user.partner.nickname ||
        !this.user.partner.name ||
        !this.user.partner.phone ||
        !this.user.partner.tel ||
        !this.user.email ||
        this.user.partner.business.business_type === null ||
        this.user.partner.business.business_type === undefined
      ) {
        result = true;
      }
      return result;
    },
    clickProfile() {
      this.$refs.profileModal.close()
      setTimeout(() => {
        this.$router.push('/partner_center/partner_info')
      }, 500)
    }
  }
};
</script>
<style lang="stylus" scoped>
.developer-center
  padding 40px 0
  display flex
  align-items center
  gap 80px
.developer-center-content
  display flex
  flex-direction column
  gap 12px
  word-break keep-all

.valuable
  background var(--sub-color-gradient, linear-gradient(90deg, #7B61FF 30.53%, #240E94 51.68%))
  background-clip text
  -webkit-background-clip text
  -webkit-text-fill-color transparent
.status-container
  padding-top 36px
.buttons
  display flex
  gap 12px
  a
    width 100%
    flex 1
  button
    width 100%
    flex 1
    font-size 16px
    font-weight 500
    height 52px

.status
  display flex
  align-items center
  gap 8px
  padding 12px
  border-radius 8px
  font-size 14px
  font-weight 400
  &.status-1
  &.status-3
    background-color #FFF0F0
    color #FF3B3B
  &.status-2
    background-color #F3FAF7
    color #0063F7
.modal-confirm-content
  display flex
  flex-direction column
  align-items center
  text-align center
  gap 12px
  button
    width 100%
    flex 1

@media (max-width: 600px)
  .developer-center
    flex-direction column
    gap 40px
    padding 24px 16px
</style>
<style lang="stylus">
.developer-center
  .sweet-modal
    width 343px

  .sweet-modal.is-alert .sweet-content
    padding-top 24px !important
    padding-bottom 24px !important
    padding-left 16px !important
    padding-right 16px !important
    button
      height 44px
</style>
